@import '../../../variables.css';

.mobileNav {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    height: 3.75rem;
    z-index: 24;
    background-color: var(--ark-primary-white-figma);
    display: none;
    border-top: solid 1px var(--ark-gray-extra-light-color);
}

.mobileNav.isSideMenuOpened {
    top: 0;
    z-index: 1000001;
}

.mobileNav.isSideMenuOpened.noHeaderOffset {
    top: initial;
}

.buttonsWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: auto;
}

.backIcon {
    transform: rotate(-180deg);
}

.navItem {
    background-color: transparent;
    box-shadow: none;
    border: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--ark-primary-black-figma);
    position: relative;
}

@media (max-width: 748px) {
    .mobileNav.isSideMenuOpened {
        top: 0;
    }
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .mobileNav {
        top: unset;
    }
}

@supports (-webkit-touch-callout: none) {
    @media (orientation: portrait) {
        .navItem {
            justify-content: center;
        }

        .navItemCaption {
            margin-top: 0 !important;
            margin-bottom: 0.875rem;
        }

        .navItemIcon {
            margin-top: 0.2rem;
        }

        .navItem svg {
            margin-top: 0.2rem;
        }
    }
}

.navItem.active {
    background-color: var(--ark-hover-blue-transparent-figma);
}

.navItem.active svg > * {
    fill: var(--ark-primary-blue-figma);
}

.navItem.active.stroke svg > * {
    fill: unset;
    stroke: var(--ark-primary-blue-figma);
}

.navItem.active::before {
    transform: scale(1);
}

.navItem.disabled .navItemCaption,
.navItem.disabled .navItemIcon {
    opacity: 0.28;
}

.navItemCaption {
    display: inline-block;
    font-size: var(--ark-10-font-size);
    line-height: 1;
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-primary-black-figma);
    white-space: nowrap;
    margin-top: 5px;
}

.hidden {
    display: none !important;
}

@media (max-width: var(--ark-new-med-small-mobile-360)) {
    .mobileNav {
        overflow: scroll;
        justify-content: unset;
    }

    @supports not (-webkit-touch-callout: none) {
        /* CSS for other than iOS devices */
        .mobileNav {
            direction: rtl;
        }
    }
}

@media (max-width: var(--ark-new-med-small-mobile-360)) and (orientation: portrait) {
    .buttonsWrapper {
        direction: ltr;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .mobileNav {
        display: flex;
    }

    .extendMediaQuery.mobileNav {
        display: flex;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: portrait) {
    .navItem::before {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--ark-primary-blue-figma);
        height: 3px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        transform-origin: center;
        transform: scale(0);
        transition: transform 0.3s ease;
    }

    .mobileNav {
        justify-content: space-evenly;
        top: initial;
    }

    .mobileNav.isSideMenuOpened {
        top: initial;
    }

}

@media (max-width: var(--ark-medium-mobile-figma-768)) and (orientation: portrait) {
    .navItemCaption.navItemCaptionStanNewman {
        white-space: normal;
        margin: 0;
        max-width: 50px;
    }

    .stanNewmanImage {
        margin-top: 6px;
    }
}

/* 566px when keyboard appear on portrait mode android */
@media (min-width: 566px) and (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    .mobileNav {
        top: var(--ark-mobile-header-height);
        left: 0;
        right: auto;
        height: auto;
        max-height: 100vh;
        border-top: 0;
        border-right: solid 1px var(--ark-gray-extra-light-color);
    }

    .mobileNav.isSideMenuOpened.noHeaderOffset {
        top: var(--ark-mobile-header-height);
    }

    .buttonsWrapper {
        flex-direction: column;
        justify-content: space-around;
        width: 5.625rem;
    }

    .navItem::before {
        content: ' ';
        position: absolute;
        left: 0;
        right: auto;
        top: 0;
        bottom: 0;
        background-color: var(--ark-primary-blue-figma);
        height: auto;
        width: 4px;
        border-radius: 2px;
        transform-origin: center;
        transform: scale(0);
        transition: transform 0.3s ease;
    }
}

@media (min-width: 566px) and (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    .extendMediaQuery.mobileNav.isSideMenuOpened {
        top: 0;
    }

    .extendMediaQuery.mobileNav {
        top: var(--ark-mobile-header-height);
        left: 0;
        right: auto;
        width: 5.625rem;
        height: auto;
        border-top: 0;
        border-right: solid 1px var(--ark-gray-extra-light-color);
    }

    .extendMediaQuery .buttonsWrapper {
        flex-direction: column;
        justify-content: space-around;
    }

    .extendMediaQuery .navItem::before {
        content: ' ';
        position: absolute;
        left: 0;
        right: auto;
        top: 0;
        bottom: 0;
        background-color: var(--ark-primary-blue-figma);
        height: auto;
        width: 6px;
        border-radius: 2px;
        transform-origin: center;
        transform: scale(0);
        transition: transform 0.3s ease;
    }
}
