@import '../../../variables.css';

.darkOverlay {
    display: none;
    position: fixed;
    top: var(--ark-header-height);
    z-index: 125;
    width: 100vw;
    height: calc(100vw - var(--ark-header-height));
    background-color: var(--ark-primary-black-figma);
    opacity: 0.6;
}

.darkOverlay.show {
    display: initial;
}

.darkOverlay.chatBotOverlay {
    z-index: 10000000;
}

.headerSideMenu {
    width: 51rem;
    position: fixed;
    z-index: 1000000;
    top: 0;
    right: 0;
    bottom: 0;
    background: white;
    box-shadow: -2px 2px 4px 0 var(--ark-black-15-color);
    transition: 0.6s;
}

.headerSideMenu.signOpen {
    top: 0;
    z-index: 130;
}

.headerSideMenu.signOpen .signNavigationContainer {
    display: initial;
    top: 70px;
    position: relative;
    z-index: 50;
}

.signNavigationContainer,
.headerSideMenu.signOpen .navigationContainer {
    display: none;
}

.signNavigationContainer button {
    margin: 32px 32px auto auto;
    padding: 0;
    display: block;
    background: none;
    border: none;
}

.signNavigationContainer button svg {
    width: 24px;
    height: 24px;
}

.signNavigationContainer button svg *[stroke] {
    stroke: var(--ark-primary-black-figma);
}

.myNodeEnter {
    transform: translateX(100%);
}

.myNodeExit {
    transform: translateX(0);
}

.myNodeEnterActive {
    transition: transform 0.6s;
    transform: translateX(0);
}

.myNodeExitActive {
    transition: transform 0.6s;
    transform: translateX(100%);
}

.myNodeExitDone {
    transform: translateX(100%);
}

.navigationContainer {
    box-sizing: border-box;
    box-shadow: 0 2px 4px 0 var(--ark-black-15-color);
}

.navigation {
    height: var(--ark-header-height);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 2rem;
}

.navButton {
    position: relative;
    height: 100%;
    background: none;
    border: none;
    color: var(--ark-black-color);
}

.navButton[data-element-description='side-menu-login'] {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.navButton[data-element-description='side-menu-login'] > svg {
    margin: 0 auto;
}

.navButton[data-element-description="side-menu-hide"] {
    grid-column: 6;
}

.navButton.hidden {
    display: none;
}

.navButton::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--ark-primary-blue-figma);
    height: 6px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    transform-origin: center;
    transform: scale(0);
    transition: transform 0.332s var(--ark-ease-in-out-sine);
}

.navButton::after {
    content: ' ';
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 2rem;
    left: 50%;
    transform-origin: 0;
    transform: scale(1) translateX(-50%);
    opacity: 0;
    border: 6px solid var(--ark-primary-50-color);
    transition: opacity 0.231s linear, transform 0.231s var(--ark-ease-in-out-sine);
}

.navButton:active::after {
    transform: scale(0.6) translateX(-50%);
    opacity: 0.5;
    transition: 0s;
}

.navButtonIcon {
    display: block;
    margin: auto;
    color: #31323d;
    transition: color 0.531s var(--ark-ease-in-out-sine);
}

.navButton:not(.activePage):hover {
    color: var(--ark-primary-blue-figma);
}

.navButton:hover .navButtonIcon > * {
    fill: var(--ark-primary-blue-figma);
}

.navButton:hover .navButtonIconAlt > * {
    fill: none;
    stroke: var(--ark-primary-blue-figma);
}

.navButton[data-element-description='side-menu-hide']:hover .navButtonIcon {
    color: var(--ark-primary-blue-figma);
}

.navButton[data-element-description='side-menu-hide']:hover .navButtonIcon > * {
    fill: none;
}

.navButton.activePage {
    background-color: var(--ark-hover-blue-transparent-figma);
}

.navButton.activePage .navButtonIcon > * {
    fill: var(--ark-primary-blue-figma);
}

.navButton.activePage .navButtonIconAlt > * {
    fill: none;
    stroke: var(--ark-primary-blue-figma);
}

.navButton.activePage::before {
    transform: scale(1);
}

.backButton {
    display: flex;
    justify-content: flex-end;
    height: 5.75rem;
}

.backButton .navButton {
    width: 8.75rem;
    height: 6.75rem;
    padding-bottom: 1rem;
    padding-right: 0;
}

.iconCaption {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-22-line-height);
    color: currentColor;
}

.content {
    display: block;
    padding: 0;
    overflow: auto;
    height: calc(100% - var(--side-menu-horizontal-size));
    box-sizing: border-box;
    position: relative;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {

    .headerSideMenu.signOpen {
        top: var(--ark-mobile-header-height);
    }

    .headerSideMenu.signOpen .signNavigationContainer {
        display: none;
    }

    .logoWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1.25rem;
        padding-bottom: 2.5rem;
    }

    .closeIcon {
        position: absolute;
        right: 1.25rem;
        top: 1.5rem;
    }

    .closeIconWhite {
        color: var(--ark-neutral-white);
        z-index: 1;
    }

    .isLogInTab,
    .isShopTab {
        padding-top: 0 !important;
    }

    .headerSideMenu.extendMediaQuery {
        width: 100%;
        padding-top: 0;
        height: auto;
        transform: translateY(0);
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.64s var(--ark-ease-in-out-sine), padding-top 0.64s var(--ark-ease-in-out-sine);
    }

    .headerSideMenu.extendMediaQuery.isOpened {
        padding-top: var(--ark-spacing-m);
        max-height: 100vh;
    }

    .headerSideMenu.extendMediaQuery .navButton::before {
        content: ' ';
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        height: 4px;
        bottom: -1rem;
    }

    .headerSideMenu.extendMediaQuery.isHelpTab {
        padding-top: 0;
    }

    .headerSideMenu.extendMediaQuery.isHelpTab .navigationContainer {
        display: none;
    }

    .headerSideMenu.extendMediaQuery .navigationContainer {
        grid-template-columns: 1fr 1fr;
        grid-gap: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 1rem;
    }

    .headerSideMenu.extendMediaQuery .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .headerSideMenu.extendMediaQuery .navigationContainer .buttonContainer:nth-child(2) {
        grid-column-start: 2;
        grid-column-end: 3;
    }

    .headerSideMenu.extendMediaQuery .navButton {
        width: 8.75rem;
    }

    .headerSideMenu.extendMediaQuery .supportButton {
        display: none;
    }

    .headerSideMenu.extendMediaQuery .backButton {
        display: none;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    .headerSideMenu.extendMediaQuery .content {
        height: calc(100% - var(--side-menu-horizontal-size));
        padding-left: 4.5rem;
        padding-bottom: 5.5rem;
    }

    .headerSideMenu.extendMediaQuery.isHelpTab .content {
        height: 100%;
        padding-bottom: 1rem;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .myNodeEnter {
        /* transform: translateY(-100%); */
        /* transition: transform 0.6s;  */
    }

    .myNodeEnterActive {
        transition: transform 0.6s;
        /* transform: translateY(-100%); */
    }

    .myNodeEnterDone {
        transition: transform 0.6s;
        transform: translateY(0);
    }

    .myNodeExit {
        transform: translateY(0);
        transition: transform 0.6s;
    }

    .myNodeExitActive {
        transition: transform 0.6s;
        transform: translateY(-100%);
    }

    .myNodeExitDone {
        transform: translateY(-100%);
        transition: transform 0.6s;
    }

    .headerSideMenu.myNodeEnterDone {
        transform: translateY(0);
    }

    .headerSideMenu {
        width: 100%;
        padding-top: 0;
        height: auto;
        transform: translateY(-100%);
    }

    .headerSideMenu.isAnimated {
        transition: max-height 0.64s var(--ark-ease-in-out-sine), padding-top 0.64s var(--ark-ease-in-out-sine);
    }

    .headerSideMenu.isOpened {
        padding-top: 3rem;
    }

    .navButton::before {
        content: ' ';
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        height: 4px;
        bottom: -1rem;
    }

    .headerSideMenu.isHelpTab,
    .headerSideMenu.headerSideMenu.isOpened.isOpenedHome.isHelpTab {
        padding-top: 0;
    }

    .headerSideMenu.isHelpTab .navigationContainernavigationContainer {
        display: none;
    }

    .navigationContainer {
        grid-template-columns: 1fr 1fr;
        grid-gap: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 1rem;
        display: none;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .navigationContainer .buttonContainer:nth-child(2) {
        grid-column-start: 2;
        grid-column-end: 3;
    }

    .navButton {
        width: 8.75rem;
    }

    .supportButton {
        display: none;
    }

    .backButton {
        display: none;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    .content {
        height: 100%;
        padding-left: 5.5rem;
        padding-bottom: 5.5rem;
    }

    .headerSideMenu.isHelpTab .content {
        height: 100%;
        padding-bottom: 1rem;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .content {
        padding: 0 0 5.5rem 0;
    }

    .noOverflowY {
        overflow-y: hidden;
    }

    .headerSideMenu.isHelpTab .content {
        padding-bottom: 1rem;
    }

    .headerSideMenu.isShopTab .content,
    .headerSideMenu.isLogInTab .content {
        height: 100%;
        padding-bottom: 0;
    }
}

@media (min-width: var(--ark-large-mobile-768)) {
    .headerSearch {
        width: 33.5rem;
        margin: 0 auto;
    }
}

@media (max-width: var(--ark-large-mobile-768)) {
    .headerSearch {
        padding: 0 1.25rem;
    }
}
